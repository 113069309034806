import React from "react";

import Accordion from "components/Accordion";
import { FAQsProps } from "../types";

import * as styles from "./styles.module.scss";

type FAQsListProps = {
  faqs: FAQsProps[];
};

const List: React.FC<FAQsListProps> = ({ faqs }) => {
  return (
    <div className={styles.faqs}>
      <div className="container small">
        {faqs.map((i: FAQsProps, key: any) => {
          return (
            <React.Fragment key={key}>
              {i.category && (
                <div className={styles.category}>
                  <h2>{i.category.title}</h2>
                </div>
              )}
              {i.accordion && <Accordion accordion_list={i.accordion.accordion_list} noContainer={true} />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default List;
