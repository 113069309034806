import React from "react";
import clsx from "clsx";

import AccordionItem, { AccordionItemProps } from "./AccordionItem";

import * as styles from "./styles.module.scss";

export type AccordionProps = {
  accordion_list: AccordionItemProps[];
  noContainer?: boolean;
  lazyload?: boolean;
};
const Accordion: React.FC<AccordionProps> = ({ accordion_list, noContainer, lazyload }) => {
  const className = clsx(styles.accordion, lazyload && "componentLazyload");
  return noContainer ? (
    <ul className={className}>
      {accordion_list?.map((i: AccordionItemProps, key: any) => (
        <AccordionItem item={i.item} key={key} />
      ))}
    </ul>
  ) : (
    <div className={className}>
      <div className="container small">
        <ul>
          {accordion_list?.map((i: AccordionItemProps, key: any) => (
            <AccordionItem item={i.item} key={key} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Accordion;
