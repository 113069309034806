import React from "react";
import { graphql } from "gatsby";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import { useBreadcrumbs } from "modules/breadcrumbs/hooks";
import { HeroBannerProps } from "components/Hero/Banner";
import { FAQsProps } from "components/FAQs/types";
import { FAQsContactProps } from "components/FAQs/Contact";
import { DefaultMetaType } from "modules/meta/types";
import { NewsletterPopupType } from "modules/newsletter/types";

import * as FAQs from "components/FAQs";

type FaqsPageProps = { data: QueryTypes; location: any };

type QueryTypes = {
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackGlobalMeta: DefaultMetaType;
  contentstackFaqsPage: {
    title: string;
    meta: {
      window_title: string;
      meta_description: string;
    };
    newsletter: {
      show_popup_page: string;
    };
    hero_banner: HeroBannerProps;
    faqs: FAQsProps[];
    contact: FAQsContactProps;
  };
};

const FaqsPage: React.FC<FaqsPageProps> = ({ data, location }) => {
  /* Breadcrumbs */
  const { breadcrumbs } = useBreadcrumbs(location);
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.contentstackFaqsPage;

  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  return (
    <FAQs.Template
      contact={page?.contact}
      crumbs={breadcrumbs}
      defaultMeta={data.contentstackGlobalMeta}
      faqs={page?.faqs}
      hero_banner={page?.hero_banner}
      location={location}
      meta={page?.meta}
    />
  );
};

export const query = graphql`
  query ($id: String) {
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    contentstackFaqsPage(id: { eq: $id }) {
      id
      title
      ...metaFaqsPage
      newsletter {
        show_popup_page
      }
      ...faqsHeroBanner
      faqs {
        ...faqAccordion
        category {
          title
        }
      }
      contact {
        title
        button_label_form_title
      }
    }
  }
`;

export default FaqsPage;
