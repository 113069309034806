import { useCallback, useEffect, useRef, useState } from "react";

export const useAccordion = (closed?: boolean) => {
  const [animating, setAnimating] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(!closed);
  const ref = useRef<HTMLDivElement>(null);

  const handleTransition = useCallback((open: boolean, element: HTMLDivElement) => {
    const height = element.scrollHeight;
    element.style.height = `${open ? height : 0}px`;
    setAnimating(true);
    requestAnimationFrame(() => {
      element.style.height = `${open ? 0 : height}px`;
    });
  }, []);

  const handleTransitionEnd = useCallback((event: TransitionEvent) => {
    if (event.target === ref.current && event.propertyName === "height") {
      setAnimating(false);
      ref.current?.style.removeProperty("height");
    }
  }, []);

  const handleClick = useCallback(() => {
    setOpen(!open);
    !!ref.current && handleTransition(open, ref.current);
  }, [handleTransition, open]);

  useEffect(() => {
    const element = ref.current;
    element?.addEventListener("transitionend", handleTransitionEnd);
    return () => element?.removeEventListener("transitionend", handleTransitionEnd);
  }, [handleTransitionEnd]);

  return { handleClick, open, animating, ref };
};
