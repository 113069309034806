import React, { useState } from "react";

import * as Button from "components/Button";
import ContactForm from "components/ContactForm";

import * as styles from "./styles.module.scss";
import { ButtonStyle } from "components/Button/constants";

export type FAQsContactProps = {
  title: string;
  button_label_form_title: string;
};

const Contact: React.FC<FAQsContactProps> = ({ title, button_label_form_title }) => {
  const heading = title ? title : "Answer not found?";
  const label = button_label_form_title ? button_label_form_title : "I require further assistance";
  const [open, setOpen] = useState(false);

  return !open ? (
    <section className={styles.faqsContact}>
      <div className="container small">
        <h2>{heading}</h2>
        <Button.Generic label={label} onClick={() => setOpen(true)} style={ButtonStyle.BRAND_BLACK_GRAPE} />
      </div>
    </section>
  ) : (
    <ContactForm title={label} autoHeight />
  );
};

export default Contact;
