import React from "react";
import clsx from "clsx";

import { useAccordion } from "./hooks";
import * as Button from "components/Button";
import RichText from "components/RichText";

import * as styles from "./styles.module.scss";

export type AccordionItemProps = {
  item: {
    title: string;
    closed?: boolean;
    content?: AccordionAnswerProps[];
  };
};

export type AccordionAnswerProps = {
  copy?: {
    copy: string;
  };
};

const AccordionItem: React.FC<AccordionItemProps> = ({ item: { title, closed = true, content } }) => {
  const { animating, handleClick, open, ref } = useAccordion(closed);
  const className = clsx(styles.accordionItem, !open && !animating && styles.closed, !open && styles.buttonClosed);
  return (
    <li className={className}>
      <div>
        <div>
          <h3>{title}</h3>
          <Button.Generic
            ariaLabel={!open ? `Open ${title} accordion` : `Close ${title} accordion`}
            onClick={handleClick}
          >
            <span></span>
          </Button.Generic>
        </div>
      </div>
      <div aria-hidden={!open} ref={ref}>
        <div>
          {content?.map((i: AccordionAnswerProps, key: any) => {
            return i.copy ? <RichText content={i.copy.copy} key={key} /> : null;
          })}
        </div>
      </div>
    </li>
  );
};

export default AccordionItem;
