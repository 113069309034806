import React from "react";

import Meta from "components/Meta";
import Layout from "components/Layout";
import * as Hero from "components/Hero";
import * as FAQs from "components/FAQs";
import { FAQsTemplateProps } from "../types";

const Template: React.FC<FAQsTemplateProps> = ({ contact, crumbs, defaultMeta, faqs, hero_banner, location, meta }) => {
  return (
    <Layout>
      <Meta defaultMeta={defaultMeta} location={location} meta={meta} />
      <div>
        <Hero.Banner crumbs={crumbs} {...hero_banner} />
        <FAQs.List faqs={faqs} />
        <FAQs.Contact {...contact} />
      </div>
    </Layout>
  );
};

export default Template;
